import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function PrereatmentPage() {
  return (
    <div
      className="section-full p-t40 p-b50"
      // style={{
      //   backgroundImage: `url(${publicUrlFor(
      //     "images/background/bg-map.png"
      //   )})`,
      // }}
    >
      <div className="container">
        {" "}
        <div className="my-3">
          <div className="">
            <p
              className=""
              style={{
                fontWeight: "460",
                fontSize: 24,
              }}
            >
              Effluent Treatment Chemicals
            </p>
            {/* <div className="card my-3"> */}
            {/* <div className="card-body"> */}
            {/* <span className="text-info display-block title-second text-uppercase font-20 font-weight-600 display-block m-b5">
              <i> Parer Slimicides </i>
            </span> */}
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Reuse of water is key to preserving our natural resources and
              waste water treatment is an integral part of this. The right
              products and approach can help you to improve the quality of their
              waste water discharge thus minimising environmental impact.
            </p>
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              The particular challenges of waste water treatment vary from site
              to site and industry to industry. The challenges may include
              issues from both inorganic and organic contaminants which can be
              detrimental to the toxicity, pH, colour, odour and/or oxygen
              demand of receiving waters.
            </p>
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Comet Chemicals understands the complex chemical requirements of
              industrial wastewater treatment and has technical resources
              dedicated to support the needs of our customers operating in this
              area. We are committed to innovation in the solutions and
              technology we supply, and to providing the highest level of
              service to our customers. To support we have a broad range of
              chemistry solutions designed specifically for use in wastewater
              treatment.
            </p>
            <ol
              className="ms-3 fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              <li>&nbsp;Coagulants</li>
              <li>&nbsp;Flocculants</li>
              <li>&nbsp;Antifoam</li>
              <li>&nbsp;⁠Odour Control</li>
              <li>&nbsp;pH adjustment</li>
            </ol>
            <p
              className="fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              Our knowledge and experience allows us to make suggestions for the
              right treatment programme for your wastewater. However, the
              parameters vary widely even in treating the waste from the same
              industry so that some product optimisation in terms of product
              selection and dose rate will be required. Please contact us to
              provide your wastewater better treatment.
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrereatmentPage;
