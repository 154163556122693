import FastImage from "../../../globals/elements/fastimg";
import { route } from "../../../globals/constants";
// import { route } from "../../../../globals/constants";
import SectionHome1Plans from "../../sections/home/home1/section-home1-plans";
import SectionHome1Team from "../../sections/home/home1/section-home1-team";
import SectionHome2Video from "../../sections/home/home2/section-home2-video";
import SectionHome3WhatWeDo from "../../sections/home/home3/section-home3-what-we-do";
import SectionHome3WhyChooseUs from "../../sections/home/home3/section-home3-why-choose-us";
import { publicUrlFor } from "../../../globals/constants";
import { NavLink } from "react-router-dom";

function AboutUsPage() {
  return (
    <>
      <div
        className="section-full bg-white p-t80 bg-center bg-full-height bg-no-repeat"
        // style={{
        //   backgroundImage: `url(${publicUrlFor(
        //     "images/background/bg-map.png"
        //   )})`,
        // }}
      >
        <div className="container ">
          <div className="my-3">
            <div className="">
              <p
                className="justify-content-center fs-6"
                style={{
                  fontWeight: "400",
                }}
              >
                Comet Chemicals is the leading manufacturer and service provider
                of integrated water treatment and process improvement services,
                chemicals and equipment programs for a variety of industrial and
                institutional customers. Our products and services are used in
                water treatment applications to prevent scale, corrosion,
                contamination and the buildup of harmful deposits, or in
                production processes to enhance process efficiency and improve
                our customers&rsquo; end products
              </p>
              <p
                className="justify-content-center fs-6"
                style={{
                  fontWeight: "400",
                }}
              >
                During the past two decades, Comet Chemicals has grown from a
                fledgling water treatment business to a leading provider of
                integrated water treatment and process improvement services,
                chemicals and equipment programs for industrial and
                institutional applications. We do this by focusing on our core
                markets of water treatment and process chemicals and on our
                corporate strengths - global presence, on-site expertise, and
                innovative products and services.
              </p>
            </div>
            <br />
            <p
              className="justify-content-center fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              We innovate water treatment solutions with visionary design
              principles.
            </p>
            <p
              className="justify-content-center fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              Advanced engineering and innovative planning tailored for water
              treatment solutions.
              <br />{" "}
            </p>
            <p
              className="justify-content-center fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              For over two decades, Comet Group has been renowned globally for
              delivering exceptional water treatment solutions, exceeding client
              expectations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default AboutUsPage;
