import { route, publicUrlFor } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";

function SectionFooter1(props) {
  return (
    <>
      <footer className={"site-footer " + props.theme}>
        {/* Footer Top Part */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container mb-2">
            <div className="row">
              {/* About */}
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className="widget_about">
                  <h3 className="widget-title">Contact Us</h3>
                  <div className="logo-footer clearfix mt-2">
                    <NavLink to={route.home.HOME1}>
                      <FastImage
                        src="media/logos/Comet-logo.png"
                        width={95}
                        height={30}
                        alt=""
                      />
                    </NavLink>
                  </div>
                  <ul className="list-unstyled m-t10">
                    <li>
                      <i className="fa fa-location-arrow site-text-primary fs-5" />
                      <b className="p-lr5 text-uppercase">Address :- </b>
                      <p className="mt-1 ms-4" style={{ fontSize: 15 }}>
                        FF-01 Block C-2, Nirman Complex, R C Technical Road,
                        Ghatlodia, Ahmedabad
                      </p>
                    </li>
                    <li>
                      <p style={{ fontSize: 15 }}>
                        <i className="fa fa-phone site-text-primary" />
                        &nbsp;&nbsp;&nbsp;+91-98240 40260
                      </p>
                    </li>
                    <li>
                      <p style={{ fontSize: 15 }}>
                        <i className="fa fa-phone site-text-primary" />
                        &nbsp;&nbsp;&nbsp;+91- 98244 97153
                      </p>
                    </li>
                    <li>
                      <p style={{ fontSize: 15 }}>
                        <i className="fa fa-envelope site-text-primary" />
                        &nbsp;&nbsp;&nbsp;
                        <u>info@cometgroup.co.in</u>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Products */}
              <div className="col-lg-5 col-md-6 col-sm-12 m-b10">
                <div className="widget_services">
                  <h3 className="widget-title">Products</h3>
                  <ul className="nav navbar-nav text-uppercase text-decoration-underline">
                    <li>
                      <NavLink to={route.pages.product.BoilerWaterChemicals}>
                        Boiler Water Chemicals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.CoolingWaterChemicals}>
                        Cooling Water Chemicals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.EffluentTreatmentChemicals}>
                      Effluent Treatment Chemicals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.SideTreatments}>
                        Fire Side Treatments
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.RoAntiScalantKits}>
                        RO Anti-Scalant & De-Scalant
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.TestKits}>Test Kits</NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.DescalingChemicals}>
                        Descaling Chemicals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.ChillingWaterChemicals}>
                        Chilling Water Chemicals
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Useful Links */}
              <div className="col-lg-2 col-md-6 col-sm-12">
                <div className="widget_services m-b10">
                  <h3 className="widget-title">Useful links</h3>
                  <ul className="text-uppercase list-unstyled text-decoration-underline">
                    <li>
                      <NavLink to={route.pages.ABOUT}>About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to={route.services.SERVICES}>Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.knowledge.KNOWLEDGE}>
                        Knowledge
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink to={route.pages.chemical.CHEMICALS}>
                        Chemicals
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink to={route.pages.SUPPORT}>Support</NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.CONTACTUS}>Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container p-t30">
            <div className="mt-footer-bot-center text-center">
              <span className="copyrights-text fs-6">
                2024 © Copyright CometGroup. All rights reserved. Read Legal
                policy and Privacy policy.
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default SectionFooter1;
