import { Routes, Route } from "react-router-dom";
import { route } from "../globals/constants";
import HomePage from "../app/components/home/newHome";
import InnerPageLayout from "../layouts/inner-page-layout";
import AboutUsPage from "../app/components/pages/about-us";
import KnowledgePage from "../app/components/pages/knowledge/KnowledgePage";
import ChemicalPage from "../app/components/pages/chemicals/ChemicalPage";
import ContactUsPage from "../app/components/pages/contact/contact-us";
import BoilerFeedChemical from "../app/components/pages/products/BoilerFeedChemical";
import CoolingWaterChemical from "../app/components/pages/products/CoolingWaterChemical";
import FireSideTreatment from "../app/components/pages/products/FireSideTreatment";
import RoAntiScalantKit from "../app/components/pages/products/RoAntiScalantKit";
import TestKits from "../app/components/pages/products/TestKits";
import ParerSlimicides from "../app/components/pages/products/ParerSlimicides";
import PrereatmentPage from "../app/components/pages/products/PrereatmentPage";
import BannerSupportPage from "../app/components/pages/support/BannerSupportPage";
import FeaturesPage from "../app/components/pages/features/features";
import Error404 from "../app/components/pages/error404";
import ServicesPage from "../app/components/services/services";
import CometProducts from "../app/components/pages/products/CometProducts";

function LayoutRoute({ content }) {
  return <InnerPageLayout content={content} />;
}

function AppRoutes() {
  return (
    <Routes>
      {/* Home Route */}
      <Route path={route.INITIAL} element={<HomePage />} />
      <Route path={route.home.HOME1} element={<HomePage />} />

      {/* About Us Page */}
      <Route
        path={route.pages.ABOUT}
        element={<LayoutRoute content={<AboutUsPage />} />}
      />

      {/* Knowledge Page */}
      <Route
        path={route.pages.knowledge.KNOWLEDGE}
        element={<LayoutRoute content={<KnowledgePage />} />}
      />

      {/* Chemicals Page */}
      <Route
        path={route.pages.chemical.CHEMICALS}
        element={<LayoutRoute content={<ChemicalPage />} />}
      />

      {/* Services Page */}
      <Route
        path={route.services.SERVICES}
        element={<LayoutRoute content={<ServicesPage />} />}
      />

      {/* Contact Us Page */}
      <Route
        path={route.pages.CONTACTUS}
        element={<LayoutRoute content={<ContactUsPage />} />}
      />

      {/* ======================== Products Pages ================================== */}
      <Route
        path={route.pages.product.PRODUCTS}
        element={<LayoutRoute content={<CometProducts />} />}
      />
      <Route
        path={route.pages.product.BoilerWaterChemicals}
        element={<LayoutRoute content={<BoilerFeedChemical />} />}
      />
      <Route
        path={route.pages.product.CoolingWaterChemicals}
        element={<LayoutRoute content={<CoolingWaterChemical />} />}
      />
      <Route
        path={route.pages.product.EffluentTreatmentChemicals}
        element={<LayoutRoute content={<PrereatmentPage />} />}
      />
      <Route
        path={route.pages.product.SideTreatments}
        element={<LayoutRoute content={<FireSideTreatment />} />}
      />
      <Route
        path={route.pages.product.RoAntiScalantKits}
        element={<LayoutRoute content={<RoAntiScalantKit />} />}
      />
      <Route
        path={route.pages.product.TestKits}
        element={<LayoutRoute content={<TestKits />} />}
      />
      <Route
        path={route.pages.product.DescalingChemicals}
        element={<LayoutRoute content={<ParerSlimicides />} />}
      />
      <Route
        path={route.pages.product.ChillingWaterChemicals}
        element={<LayoutRoute content={<PrereatmentPage />} />}
      />

      {/* Support Page */}
      <Route
        path={route.pages.SUPPORT}
        element={<LayoutRoute content={<BannerSupportPage />} />}
      />

      {/* Features Pages */}
      <Route
        path={route.pages.features.footer.FIXED}
        element={<LayoutRoute content={<FeaturesPage />} />}
      />
      <Route
        path={route.pages.features.footer.LIGHT}
        element={<LayoutRoute content={<FeaturesPage />} />}
      />
      <Route
        path={route.pages.features.footer.DARK}
        element={<LayoutRoute content={<FeaturesPage />} />}
      />

      {/* 404 Error Page */}
      <Route path="*" element={<LayoutRoute content={<Error404 />} />} />
    </Routes>
  );
}

export default AppRoutes;
