import { NavLink } from "react-router-dom";
import FastImage from "../../../globals/elements/fastimg";
import { route, publicUrlFor, loadScript } from "../../../globals/constants";
import { useEffect } from "react";
import _data from "../../../globals/data/data.json";

function ServicesPage() {
  const { explore, services } = _data.services;
  const { clients } = _data;

  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      <div className="section-full bg-gray p-t80 p-b50">
        <div className="container justify-contect-center">
          {" "}
          <div className="my-5">
            <div className="">
              {/* <h5 className="card-title">Special title treatment</h5> */}
              <p
                className="card-text fs-6"
                style={{
                  fontWeight: "400",
                }}
              >
                We serve you by analyze concern water on site i.e. cooling
                water, boiler water, boiler feed water, condensate water, make
                up water, effluent etc. We are giving guidelines to your
                operators as well as on present programmed & future programmed
                with the aspect of energy saving.
              </p>
              <span
                className="display-block title-second display-block py-4 m-b15"
                style={{
                  fontWeight: "460",
                  fontSize: 20,
                }}
              >
                We Analyze :
              </span>
              <div className="col-lg-6 col-md-6">
                {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
                {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
                <FastImage
                  src="media/bgimg/WeAnalyze.jpg"
                  width={500}
                  height={10}
                  alt=""
                />
              </div>
              <p
                className="fs-6 py-3"
                style={{
                  fontWeight: "400",
                }}
              >
                1. &nbsp; pH,
                <br /> 2. &nbsp;TDS,
                <br /> 3. &nbsp; Hardness,
                <br /> 4. &nbsp; Phenolphthalein Alkalinity,
                <br /> 5. &nbsp; Mthyl orange alkalinity,,
                <br /> 6. &nbsp; Calcium Hardness,
                <br /> 7. &nbsp; Silica,
                <br /> 8. &nbsp; Iron,
                <br /> 9. &nbsp; Nitrate,
                <br /> 10. &nbsp; Sulphate,
                <br /> 11. &nbsp; Phosphate,
                <br /> 12. &nbsp; COD-BOD,
                <br /> 13. &nbsp; Oil & Grees contaminations,
                <br /> 14. &nbsp; Suspended solids etc..
                <br />
              </p>
            </div>
          </div>
          <div className="my-5">
            <div className="">
              <span
                className="display-block title-second display-block m-b25"
                style={{
                  fontWeight: "460",
                  fontSize: 20,
                }}
              >
                Treatment Programme Scale Prevention:
              </span>
             
              <ul
                className="card-text fs-6 m-t20"
                style={{
                  fontWeight: "400",
                }}
              >
                {" "}
                <li className="ms-4"> Over designing of systems.</li>
                <li className="ms-4"> Limiting the cycle of concentration.</li>
                <li className="ms-4"> Softening the make up water.</li>
                <li className="ms-4">
                  {" "}
                  Acid feed to maintain the pH (suitable to maintain the LSI
                  around +0.2).
                </li>
                <li className="ms-4">
                  {" "}
                  Mechanical means like increase water velocity or using sponge
                  balls for on pipe line cleaning.
                </li>
                <li className="ms-4">
                  {" "}
                  Treatment with Chemicals. In all these methods there are
                  advantages and disadvantages.
                </li>
              </ul>
               {/* <br /> */}
               <span
                className="card-text fs-6 m-t20"
                style={{
                  fontWeight: "500",
                }}
              >
                In all these methods there are advantages and disadvantages.
              </span>
              <p
                className="card-text fs-6 m-t15 justify-content-between"
                style={{
                  fontWeight: "400",
                }}
              >
                Hence let us look at the <b>TREATMENT WITH CHEMICALS.</b></p> <p
                className="card-text fs-6 m-t10 justify-content-between"
                style={{
                  fontWeight: "400",
                }}
              > Treatment
                with chemicals are most practical and cost effective method to
                control deposition. Scale formation is controlled by mechanize
                of threshold inhibition and crystal distortion. Threshold
                phenomenon is a mechanism by which substochimetric amounts of
                the chemical prevents or retards the growth of scale forming
                crystals. These chemicals are absorbed on the surface of the
                crystals and, interface with the nucleation of the scalant
                crystals preventing orderly lattice type growth. Crystal
                formation is retarded and when they are formed they are highly
                distorted, leading to a soft friable scale, which can be easily
                dispersed by the movement of water. This property permits
                operation of the cooling water systems at higher pH, and high
                cycle of concentration.
              </p>
            </div>
          </div>
          <div className="my-5">
            <div className="">
              <span
                className="display-block title-second display-block m-b15"
                style={{
                  fontWeight: "460",
                  fontSize: 20,
                }}
              >
                Monitoring of Treatment Chemicals
              </span>
              <p
                className="card-text fs-6 m-t25"
                style={{
                  fontWeight: "400",
                }}
              >
                The success of any treatment program is based on the parameters
                being maintained and to achieve this closed monitoring of water
                parameters and chemicals dosage should carried out. The
                evaluation should be done for, <br />
                1. &nbsp;Corrosion.
                <br /> 2. &nbsp; Scale & Fouling.
                <br /> 3. &nbsp; Micro biological growth.
                <p
                  className="card-text fs-6 m-t2"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  The method of monitoring these problems may be referred in our
                  site by navigating technical service page.
                </p>
              </p>
            </div>
          </div>
          {/* TITLE START*/}
          {/* <div className="section-head text-center">
                        <h2 data-title="Explore">Explore further</h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div> */}
          {/* TITLE END*/}
          {/* <div className="section-content">
                        <div className="row d-flex justify-content-center">

                            {
                                explore.map((_item, index) => {
                                    return <div key={index} className="col-lg-4 col-md-6">
                                        <div className="mt-icon-box-wraper m-b30  p-a30 center bg-white">
                                            <div className="mt-icon-box-sm site-bg-primary m-b20 radius-10">
                                                <span className="icon-cell text-white"><i className={_item.icon} /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="mt-tilte">{_item.title}</h4>
                                                <p>{_item.desc_short}</ fs-6p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div> */}
          {/* Our Services */}
          {/* <div className="section-full bg-white p-t80 p-b50">
                <div className="container"> */}
          {/* TITLE START*/}
          {/* <div className="section-head text-center">
                        <h2 data-title="Services">Our Services</h2>
                        <div className="mt-separator-outer">
                            <div className="mt-separator site-bg-primary" />
                        </div>
                    </div> */}
          {/* TITLE END*/}
          {/* <div className="mfp-gallery clearfix">
                        <div className="row d-flex justify-content-center">

                            {
                                services.map((_item, index) => {
                                    return <div key={index} className="col-lg-4 col-md-6 m-b30">
                                        <div className="mt-box bg-white bdr-1 bdr-solid bdr-gray-light mt-service-1">
                                            <div className="mt-thum-bx mt-img-overlay3 mt-img-effect">
                                                <FastImage src={_item.image} alt="" />
                                                <div className="overlay-bx">
                                                    <div className="overlay-icon">
                                                        <NavLink to={route.services.DETAIL}>
                                                            <i className="fa fa-external-link-square mt-icon-box-xs" />
                                                        </NavLink>
                                                        <a className="mfp-link" href={publicUrlFor(_item.image_preview)}>
                                                            <i className="fa fa-search-plus mt-icon-box-xs" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-info p-a20">
                                                <h4 className="m-t0">{_item.title}</h4>
                                                <p>{_item.desc_short}</ fs-6p>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div> */}
          {/* </div>
            </div>
            <div className="section-full bg-gray">
                <div className="container">
                    <div className="section-content">
                        <div className="owl-carousel client-logo-carousel owl-btn-vertical-center">

                            {
                                clients.map((_item, index) => {
                                    return <div key={index} className="item">
                                        <div className="ow-client-logo">
                                            <div className="client-logo mt-img-effect on-color">
                                                <NavLink to={route.pages.ABOUT}><FastImage src={_item} alt="" /></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}
export default ServicesPage;
