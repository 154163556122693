import { useEffect } from "react";
import { loadScript, route, publicUrlFor } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import CountUp from "react-countup";
import SectionSlider1 from "../../sections/home/home1/section-slider1";
import { NavLink } from "react-router-dom";
import SectionHome1Team from "../../sections/home/home1/section-home1-team";
import SectionHome1Plans from "../../sections/home/home1/section-home1-plans";
import SectionHome1Blogs from "../../sections/home/home1/section-home1-blogs";
import SectionHome1Testimonials from "../../sections/home/home1/section-home1-testimonials";
import _data from "../../../globals/data/data.json";
import SectionHomeWeDo from "../../sections/home/new-home/section-home3-we-do";

function HomePage() {
  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      {/* Slider */}


      <SectionSlider1 />
      {/* <div className="w-100  p-t80 p-b50 bg-gray bg-no-repeat bg-cover bg-white"> */}
        <SectionHomeWeDo />
      {/* </div> */}
    </>
  );
}
export default HomePage;
