import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function RoAntiScalantKit() {
  return (
    <div className="section-full  p-t80 p-b50"
    // style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg15.jpg"
    //   )})`,
    // }}
    >
      <div className="container">
        {" "}
        <div className="my-5">
          <h5 className="m-t80"
              style={{
                fontWeight: "460",
                fontSize: 22,
              }}>RO Anti-Scalant Chemicals</h5>
          <div className="py-3">
            <p
              className="fs-6 m-t10"
              style={{
                fontWeight: "400",
              }}
            >
              COMET range of RO Anti-scalants & De-scalants used in Reverse Osmosis Plant for non potable purpose. The products include organic cleaning, inorganic cleaning and bio cleaning.
            
              COMET formulations for prevention of scale formation in
              R.O.Membrane for both regular and high silica range.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoAntiScalantKit;
