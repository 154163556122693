import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function ParerSlimicides() {
  return (
    <div
      className="section-full p-t40 p-b50"
      // style={{
      //   backgroundImage: `url(${publicUrlFor(
      //     "images/background/bg-map.png"
      //   )})`,
      // }}
    >
      <div className="container">
        {" "}
        <div className="my-3">
          <div className="">
            {/* <h5 className="card-title">Special title treatment</h5> */}
            {/* <div className="card my-3"> */}
            {/* <div className="card-body"> */}
            {/* <span className="text-info display-block title-second text-uppercase font-20 font-weight-600 display-block m-b5">
              <i> Parer Slimicides </i>
            </span> */}
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Comet brand descaling chemical is used to safely remove scale
              deposits from boilers, chillers, cooling towers, condensers, heat
              exchangers and even more… available in various tailer-made
              formations based on requirement.{" "}
            </p>
            <p className="p-t150"></p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParerSlimicides;
