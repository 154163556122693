import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";

function SectionHomeWeDo() {
  return (
    <>
      <div className="container m-t30">
        <div className="row justify-content-center">
          {/* Our Products Card */}
          <div className="col-lg-3 col-md-6 col-sm-12 m-b50 bg-white shadow mt-product bdr-1 bdr-solid bdr-gray-light mx-3">
            <div className="mt-icon p-a30 align-center m-b10">
              <h5
                className="mt-title m-b20"
                style={{ fontWeight: 460, fontSize: 25 }}
              >
                Our Products
              </h5>
              <p className="fs-6" style={{ fontWeight: 400 }}>
                Comet Chemicals provides top-quality water treatment solutions
                including boiler feed chemicals, cooling water treatments,
                Biocide, RO Treatment Chemicals, Effluent Treatment Chemicals,
                and Test Kits.
              </p>
              <span className="icon-arrow fs-6 site-text-primary">
                <NavLink to={route.pages.product.PRODUCTS}>
                  Learn More &nbsp; <b className="fa fa-arrow-right"></b>
                </NavLink>
              </span>
            </div>
          </div>

          {/* Our Services Card */}
          <div className="col-lg-3 col-md-6 col-sm-12 m-b50 bg-white shadow mt-product bdr-1 bdr-solid bdr-gray-light mx-3">
            <div className="mt-icon p-a30 align-center m-b25">
              <h5
                className="mt-title m-b25"
                style={{ fontWeight: 460, fontSize: 25 }}
              >
                Our Services
              </h5>
              <p className="fs-6" style={{ fontWeight: 400 }}>
                Comet Chemicals offers on-site water analysis and tailor-made
                treatment programs for efficient water management solutions.
              </p>
              <span className="icon-arrow fs-6 site-text-primary">
                <NavLink to={route.services.SERVICES}>
                  Learn More &nbsp; <b className="fa fa-arrow-right"></b>
                </NavLink>
              </span>
            </div>
          </div>

          {/* Knowledge Section Card */}
          <div className="col-lg-3 col-md-6 col-sm-12 m-b50 bg-white shadow mt-product bdr-1 bdr-solid bdr-gray-light mx-3">
            <div className="mt-icon p-a30 align-center">
              <h5
                className="mt-title m-b25"
                style={{ fontWeight: 460, fontSize: 25 }}
              >
                Knowledge
              </h5>
              <p className="fs-6" style={{ fontWeight: 400 }}>
                Discover essential insights on water composition, impurities,
                treatment methods, and effective water management strategies.
              </p>
              <span className="icon-arrow fs-6 site-text-primary">
                <NavLink to={route.pages.knowledge.KNOWLEDGE}>
                  Learn More &nbsp; <b className="fa fa-arrow-right"></b>
                </NavLink>
              </span>
            </div>
          </div>
        </div>
        {/* Additional Section */}
        {/* <div className="text-center">
          <h3 className="text-info fs-2 display-block text-center">
            A FEW WORDS ABOUT OUR COMPANY ...
          </h3> */}
        <div className="container">
          <h3
            data-title=""
            className="text-info fs-2 display-block text-center"
          >
            A FEW WORDS ABOUT OUR COMPANY ...
          </h3>
          <p
            className="ms-6 d-block justify-between-center"
            style={{
              fontWeight: "400",
              whiteSpace: 28,
              fontSize: 18,
            }}
          >
            <b>Comet Chemicals</b> is an industry leading manufacturer &
            supplier of high-quality water and wastewater treatment chemicals,
            speciality chemicals, water test kits, reagents, chemical dosing and
            control equipment. Through our commitment to cutting-edge
            technology, innovation, quality products and service excellence we
            deliver significant advantage to our valued customers and partners.
            <br />
            <br />
            Your Water Treatment Chemical manufacturing industry's global ally
            for sustainable chemical solutions.
            <br />
            <br />
            Empowering industries for a sustainable future.
          </p>{" "}
          <p
            className="ms-6 d-block align-center"
            style={{
              fontWeight: "400",
              whiteSpace: 28,
              fontSize: 18,
            }}
          >
            Comet Chemicals supplies high-quality water and wastewater treatment
            chemicals, specialty chemicals, test kits, reagents, analysis,
            dosing, and control equipment globally. Our commitment ensures
            customer advantage.
          </p>
        </div>
        {/* </div> */}
        {/* Additional Content */}
        <div className="row justify-content-center m-t20">
          <div className="col-lg-4 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-info">
              <div className="mt-thum-bx mt-img mt-img-effect zoom">
                <FastImage src="media/bgimg/BoilerWater.jpg" alt="" />
              </div>
              <div className="mt-info align-center">
                <div className="p-a20 mt-3">
                  <h5
                    className="mt-title"
                    style={{ fontWeight: 500, fontSize: 22 }}
                  >
                    Boiler Water Treatment Chemicals
                  </h5>
                  <hr />
                  <p className="fs-6" style={{ fontWeight: 400 }}>
                    Boiler Water Treatment Chemicals to improve efficiency of
                    boiler and keep it free of scale & corrosion.
                  </p>{" "}
                  <div className="text-center">
                    <NavLink to={route.pages.product.BoilerWaterChemicals}>
                      <span className="icon-arrow fs-6 site-text-primary">
                        Read More &nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-info">
              <div className="mt-thum-bx mt-img mt-img-effect zoom">
                <FastImage src="media/bgimg/CoolingWaterTreatment.jpg" alt="" />
              </div>
              <div className="mt-info align-center">
                <div className="p-a10 mt-3">
                  <h5
                    className="mt-title"
                    style={{ fontWeight: 500, fontSize: 22 }}
                  >
                    Cooling Water Treatment Chemicals
                  </h5>
                  <hr />
                  <p className="fs-6" style={{ fontWeight: 400 }}>
                    Comet Chemicals can help you design a program that will meet
                    all your cooling water system needs free from Scale,
                    Corrosion & Biocide contamination.
                  </p>{" "}
                  <div className="text-center">
                    <NavLink to={route.pages.product.CoolingWaterChemicals}>
                      <span className="icon-arrow fs-6 site-text-primary">
                        Read More &nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-info">
              <div className="mt-thum-bx mt-img mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/EffluentTreatmentChemicals.jpg"
                  alt=""
                />
              </div>
              <div className="mt-info align-center">
                <div className="p-a15 mt-3">
                  <h5
                    className="mt-title"
                    style={{ fontWeight: 500, fontSize: 22 }}
                  >
                    Effluent Treatment Chemicals
                  </h5>
                  <hr />
                  <p className="fs-6" style={{ fontWeight: 400 }}>
                    Comet Chemicals offers efficient solutions for degrading
                    organic/inorganic compounds in wastewater, enhancing overall
                    water quality.
                  </p>
                  <div className="text-center">
                    <NavLink
                      to={route.pages.product.EffluentTreatmentChemicals}
                    >
                      <span className="icon-arrow fs-6 site-text-primary ">
                        Read More &nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Additional blocks can go here */}
        </div>
        <p className="text-warning text-uppercase text-center m-b30 fs-5">
          OUR VISION FOR GROWTH IS DRIVEN BY SUSTAINABLE CHEMISTRY INNOVATION
        </p>
        <div className="row m-t50 m-b20">
          <div className="col-lg-6 ms-2 col-md-12">
            <div className="sx-shipment-wrap">
              <div className="mt-box m-b30 align-center">
                <h2 className="display-block text-start fs-3 m-t25 m-b40">
                  We've provided water treatment chemical solutions in all type
                  of industries for more than two decades
                </h2>
                <p
                  className="m-b15"
                  style={{
                    fontWeight: "400",
                    whiteSpace: 25,
                    fontSize: 18,
                  }}
                >
                  Comet Chemicals has been providing reliable, high quality,
                  cost-effective water treatment chemicals to the industry for
                  over two decades. We offer a wide range of water treatment
                  chemicals and solutions that focus on rectifying problems of
                  water and maximizing the performance of water treatment
                  chemicals. Comet Chemicals formulates & manufactures water
                  treatment chemicals through state of art manufacturing
                  facility.
                </p>
                <ul
                  className="list-check-circle primary m-t50"
                  style={{
                    fontWeight: "500",
                    whiteSpace: 28,
                    fontSize: 17,
                  }}
                >
                  <li className="">
                    Pioneer in Sustainable Manufacturing Techniques{" "}
                  </li>
                  <li>Customized solutions for better water treatment </li>
                  <li>
                    Industry Recognition for Outstanding Contributions to Green
                    Technology
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-6"></div>
          <div className="col-lg-4 mx-2 m-t20 col-md-6">
            <div className="mt-box mt-product-box bdr-1 bdr-solid bdr-info">
              <div className="mt-thum-box mt-img mt-img-effect">
                <FastImage
                  src="media/images/checmical-lab.png"
                  width={200}
                  height={10}
                  alt=""
                />
              </div>
            </div>{" "}
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default SectionHomeWeDo;
