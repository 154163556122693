export const route = {
  INITIAL: "/",
  home: {
    HOME1: "/index",
    // HOME2: "/index2",
    // HOME3: "/index3"
  },
  pages: {
    ABOUT: "/about-us",
    knowledge: {
      KNOWLEDGE: "/knowledge",
    },
    chemical: {
      CHEMICALS: "/chemicals",
    },
    sitemap: {
      SITEMAP: "/site-map",
    },
    SUPPORT: "/support",

    product: {
      PRODUCTS: "/products",
      BoilerWaterChemicals: "/products/boiler-water-chemicals",
      CoolingWaterChemicals: "/products/cooling-water-chemicals",
      SideTreatments: "/products/firstsidetreatment",
      RoAntiScalantKits: "/products/ro-anti-scalant-kits",
      TestKits: "/products/testkits",
      DescalingChemicals: "/products/descaling-chemicals",
      ChillingWaterChemicals: "/products/chilling-water-chemicals",
      EffluentTreatmentChemicals: "/products/effluent-treatment-chemicals",
    },
    CONTACTUS: "/contact-us",
    ERROR404: "/error404",
    features: {
      footer: {
        FIXED: "/footer/fixed",
        LIGHT: "/footer/light",
        DARK: "/footer/dark",
      },
    },
  },
  services: {
    SERVICES: "/services",
  },
};

export function publicUrlFor(path) {
  return process.env.PUBLIC_URL + "/assets/" + path;
}

export function loadScript(src, fromPublic) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");

    script.src =
      fromPublic === undefined || fromPublic == null || fromPublic
        ? publicUrlFor(src)
        : src;

    script.addEventListener("load", function () {
      resolve();
    });
    script.addEventListener("error", function (e) {
      reject(e);
    });

    setTimeout(() => {
      document.body.removeChild(script);
    }, 1500);
    document.body.appendChild(script);
  });
}
