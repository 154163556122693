import React from "react";
import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";

function BoilerFeedChemical() {
  return (
    <div className="section-full bg-gray p-t30 p-b50"
    // style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg15.jpg"
    //   )})`,
    // }}
    >
      <div className="container">
        <div className="row m-t80 ">
          <div className="col-lg-7 col-md-6">
            {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
            {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
            <FastImage
              src="media/bgimg/ChelatingProgram.jpg"
              width={500}
              height={15}
              alt=""
            />
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="sx-shipment-wrap">
              <div className="mt-box m-b30">
                <div className="row-container">
                  <h2 className="display-block fs-3 ">CHELATING PROGRAMME</h2>
                  <div className="mt-separator-outer m-b30">
                    <div className="mt-separator bg-warning  m-t25 m-b30" />
                  </div>
                </div>
                <ul
                  className="list-check-circle primary m-t30"
                  style={{
                    fontWeight: "500",
                    whiteSpace: 28,
                    fontSize: 17,
                  }}
                >
                  <li>
                    In this program chelating agents viz., EDTA, NTA, etc., are
                    added to feed water.
                  </li>
                  <br />
                  <li>
                    {" "}
                    These salts form water soluble complexes with calcium &amp;
                    magnesium and prevent them from precipitating. In case they
                    form scales, these salts de-stabilizes the crystalline
                    growth.
                  </li>
                  <br />
                  <li>
                    This system of treatment is discouraged as the salts may
                    carry over with steam and break into acidic components which
                    may cause corrosion in post boiler sections.&nbsp;&nbsp;
                  </li>
                  <br />
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>{" "}
      </div>
      <div className="section-full p-t80">
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-6 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">
                      CARBONATE TREATMENT PROGRAM
                    </h2>
                    <div className="mt-separator-outer m-b30">
                      <div className="mt-separator bg-warning  m-t25 m-b30" />
                    </div>
                  </div>
                  {/* <p
                    className="m-b15"
                    style={{
                      fontWeight: "400",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    We believe in tailoring our solutions to meet the unique
                    needs of each project. Our focus is on delivering innovative
                    and customized water treatment solutions.
                  </p> */}
                  <ul
                    className="list-check-circle primary m-t30"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>
                      {" "}
                      This method of treatment is implemented in boilers having
                      operating pressure within 20Kgs/cm2 where soft &amp; raw
                      water are used as boiler feed water.
                    </li>
                    <br />
                    <li>
                      The above treatment should be used in conjunction with
                      polymeric sludge conditioners for better results.
                    </li>
                    <br />
                    <li>
                      In this method of treatment precipitation of calcium &
                      magnesium, either partially or completely, are induced by
                      maintaining higher pH & higher alkalinity.
                    </li>
                    <br />
                    <li>
                      The residual hardness, if present, should be controlled
                      with the addition of polymeric sludge conditioners.
                    </li>
                  </ul>
                  {/* <div className="text-center p-tb20">
                    <a
                      className="mfp-link"
                      href={route.services.SERVICES}
                      // title="wish list"
                    >
                      <button className="site-button icon-arrow bg-warning" type="button">
                        Our Services&nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </button>
                    </a>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-6">
            </div> */}
              {/* </div>{" "} */}
              {/* </div> */}
            </div>{" "}
            <div className="col-lg-6 col-md-12">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/products-img/carbonate-treatment.png"
                width={500}
                height={25}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80">
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-7 col-md-6">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/bgimg/PhosphateProgram.jpg"
                width={600}
                height={15}
                alt=""
              />
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">
                      PHOSPHATE TREATMENT PROGRAM
                    </h2>
                    <div className="mt-separator-outer m-b30">
                      <div className="mt-separator bg-warning  m-t25 m-b30" />
                    </div>
                  </div>

                  <ul
                    className="list-check-circle primary m-t30"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>
                      {" "}
                      This method of treatment is implemented in boilers where
                      DM or RO water is used as boiler feed water make up. Also
                      in boilers having operating pressure above 20Kgs/cm2 which
                      should be having DM or RO water make up.
                    </li>
                    <br />
                    <li>
                      {" "}
                      In this method calcium is precipitated as calcium ortho
                      phosphate sludge with the addition of blended phosphates.
                    </li>
                    <br />
                    <li>
                      {" "}
                      This treatment is recommended in boilers where the feed
                      water contains low sodium, low silica &amp; have more than
                      90% condensate return.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80">
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-6 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">OXYGEN SCAVENGING</h2>
                    <div className="mt-separator-outer m-b30">
                      <div className="mt-separator bg-warning  m-t25 m-b30" />
                    </div>
                  </div>
                  {/* <p
                    className="m-b15"
                    style={{
                      fontWeight: "400",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    We believe in tailoring our solutions to meet the unique
                    needs of each project. Our focus is on delivering innovative
                    and customized water treatment solutions.
                  </p> */}
                  <ul
                    className="list-check-circle primary m-t30"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>
                      {" "}
                      Boiler feed water contains dissolved gasses viz., Oxygen,
                      Carbon dioxide and hydrozen sulphide.
                    </li>
                    <li className="m-t10">
                      These gasses are removed through degasser. Oxygen in
                      particular is removed through de-aerator.
                    </li>
                    <li className="m-t10">The presence of Oxygen causes pitting corrosion.</li>
                    <li className="m-t10">The presence of CO2 causes grooving corrosion.</li>
                    <li className="m-t10">
                      The presence of CO2 generally occur in post boiler section
                      where it is neutralized with the help of amines.
                    </li>
                    <li className="m-t10">
                      Oxygen is removed with the addition of Hydrazine or
                      Sulphites.
                    </li>
                    <li className="m-t10">
                      Sulphites are used in boilers having operating pressure
                      upto 600psi. Above 600psi sulphites will decompose and
                      loose their properties.
                    </li>
                    <li className="m-t10">
                      Above 600psi boilers hydrazine is used for removing
                      oxygen.
                    </li>
                    <li className="m-t10">
                      Apart from removing oxygen, hydrazine forms a magnatite
                      film over the metal surfaces in boiler system.
                    </li>
                    <li className="m-t10">Also it contributes to the increase of pH.</li>
                    <li className="m-t10">
                      Demerits of hydrazine are: 1. It is carcinogenic. 2. It
                      reacts very slow.
                    </li>
                    <li className="m-t10">
                      To enhance the reaction rate it is supplemented with a
                      catalyst.&nbsp;
                    </li>
                    {/* <li>
                    The residual hardness, if present, should be controlled with the addition of polymeric sludge conditioners.
                    </li> */}
                  </ul>
                  {/* <div className="text-center p-tb20">
                    <a
                      className="mfp-link"
                      href={route.services.SERVICES}
                      // title="wish list"
                    >
                      <button className="site-button icon-arrow bg-warning" type="button">
                        Our Services&nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </button>
                    </a>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-6">
            </div> */}
              {/* </div>{" "} */}
              {/* </div> */}
            </div>{" "}
            <div className="col-lg-6 col-md-12">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/bgimg/OxygenScavenging.jpg"
                width={500}
                height={25}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80">
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-7 col-md-6">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/bgimg/SludgeTreatment.jpg"
                width={600}
                height={15}
                alt=""
              />
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">SLUDGE CONDITIONING</h2>
                    <div className="mt-separator-outer m-b30">
                      <div className="mt-separator bg-warning  m-t25 m-b30" />
                    </div>
                  </div>

                  <ul
                    className="list-check-circle primary m-t30"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>
                      Boiler water contains suspended particles like
                      precipitation of calcium, magnesium, silica, etc. These
                      salts flock together and settle down at the bottom of
                      boiler drum, low velocity areas, may adhere to boiler
                      tubes to form a scale.
                    </li>
                    <br />
                    <li>
                      To prevent the above problem, a medium molecular weight
                      polymeric dispersant is added. This dispersant gives same
                      ionic charges to the precipitated salts, due to which
                      repulsion occurs among the particles. Hence all these
                      suspended matter is kept in kinetic motion and can be
                      removed freely through blow down.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80">
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-6 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">ALKALINITY BUILDER </h2>
                    <div className="mt-separator-outer m-b30">
                      <div className="mt-separator bg-warning  m-t25 m-b30" />
                    </div>
                  </div>
                  {/* <p
                    className="m-b15"
                    style={{
                      fontWeight: "400",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    We believe in tailoring our solutions to meet the unique
                    needs of each project. Our focus is on delivering innovative
                    and customized water treatment solutions.
                  </p> */}
                  <ul
                    className="list-check-circle primary m-t30"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>
                      Total alkalinity should be maintained at 15 to 20% of TDS
                      in the boiler water.
                    </li>
                    <br />
                    <li>
                      Certain amount of caustic alkalinity is required to be
                      maintained for following reasons:
                    </li>
                    <br />
                    <li>To keep silica in soluble state.</li>
                    <br />
                    <li>To keep iron in soluble state.</li>
                    <br />
                    <li>
                      In Phosphate program, in presence of caustic alkalinity
                      magnesium preferentially reacts with hydroxyl ions and
                      precipitate as mg(OH)2 which is a loose sludge and can be
                      removed through blow down freely.
                    </li>
                    <br />
                    <li>
                      Otherwise magnesium reacts with phosphate and form
                      magnesium phosphate which is a thick slimy sludge and can
                      not be removed through blow down.
                    </li>
                  </ul>
                  {/* <div className="text-center p-tb20">
                    <a
                      className="mfp-link"
                      href={route.services.SERVICES}
                      // title="wish list"
                    >
                      <button className="site-button icon-arrow bg-warning" type="button">
                        Our Services&nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </button>
                    </a>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-6">
            </div> */}
              {/* </div>{" "} */}
              {/* </div> */}
            </div>{" "}
            <div className="col-lg-6 col-md-12">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/products-img/alkalinity-builder.png"
                width={600}
                height={25}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t80">
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-7 col-md-6">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/products-img/carry-over.png"
                width={600}
                height={15}
                alt=""
              />
            </div>
            <div className="col-lg-5 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">CARRY OVER</h2>
                    <div className="mt-separator-outer m-b30">
                      <div className="mt-separator bg-warning  m-t25 m-b30" />
                    </div>
                  </div>

                  <ul
                    className="list-check-circle primary m-t30"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>
                      Some times suspended particles go along with steam. This
                      is called as carry over. This occurs due to the following
                      reasons:
                    </li>
                    <br />
                    <li>
                      Excess alkalinity causes foaming. When steam separates
                      from water surface, the bubbles at the interface breaks
                      and the suspended particles enveloped in the bubbles gets
                      carried along with steam.
                    </li>
                    <br />
                    <li>
                      Operating the boiler more than the designed capacity also
                      causes carry over.
                    </li>
                    <br />
                    <li>
                      Presence of oil, organic matter &amp; suspended matter in
                      excess causes carry over.&nbsp;&nbsp; The carry over
                      causes scaling in the post boiler sections.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section-full p-t50">
        <div className="container">
          {" "}
          <p className="text-warning text-uppercase m-t70 m-b30 fs-5">
            OUR SERVICES
          </p>
          <p className="text-start " style={{ fontSize: 32, fontWeight: 500 }}>
            What we focus
            <br />{" "}
          </p>
          <p
            className="m-b15"
            style={{
              fontWeight: "400",
              whiteSpace: 28,
              fontSize: 17,
            }}
          >
            We work on a wide range of building typologies and projects
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default BoilerFeedChemical;
{
  /* <div className="p-2">
          <div className="container-row">
            1.
            <u className="col-4">
              <i className="site-text-primary me-2 m-t5" />
              <a href="#CHELATING"name='MAIN'id="MAIN">CHELATING PROGRAMME</a>
            </u>{" "}
            <br />
            <br />
            2.
            <u className="col-4">
              <i className="site-text-primary me-2 m-t5" />
              <a href="#CARBONATE">CARBONATE PROGRAMME</a>
            </u>{" "}
            <br />
            <br />
            3.
            <u className="col-4">
              <i className="site-text-primary me-2 " />
              <a href="#PHOSPHATE">PHOSPHATE PROGRAMME</a>
            </u>
          </div>
        </div> 
        <h5 className="card-title">CHELATING PROGRAMME </h5>
       <p className="card-text">
              . {' '}In this program chelating agents viz., EDTA, NTA, etc., are added
              to feed water.<br/>. {' '} These salts form water soluble complexes with
              calcium & magnesium and prevent them from precipitating.In case
              they form scales, these salts de-stabilizes the {' '}crystalline
              growth. <br/>.{' '}This system of treatment is discouraged as the salts may
              carry over with steam and break into acidic components which may
              cause corrosion in post boiler sections.
              </p> 
        <div className="card my-3">
          <span className="card-header">
            <a name="CHELATING" id="CHELATING"></a>
            <strong className="font-16 font-weight-600"></strong>
            <strong>CHELATING PROGRAMME </strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>
          <div className="card-body ms-3">
            <ul className="card-text">
              <li>
                In this program chelating agents viz., EDTA, NTA, etc., are
                added to feed water.
              </li>
              <li>
                These salts form water soluble complexes with calcium &amp;
                magnesium and prevent them from precipitating. In case they form
                scales, these salts de-stabilizes the crystalline growth.
              </li>
              <li>
                This system of treatment is discouraged as the salts may carry
                over with steam and break into acidic components which may cause
                corrosion in post boiler sections.&nbsp;&nbsp;
              </li>
            </ul>
          </div>
        </div>
        <div className="card my-3">
          <span className="card-header">
            <a name="CARBONATE"></a>
            <strong>CARBONATE TREATMENT PROGRAM</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>
          <div className="card-body ms-3">
            <ul className="card-text">
              <li>
                This method of treatment is implemented in boilers having
                operating pressure within 20Kgs/cm2 where soft &amp; raw water
                are used as boiler feed water.
              </li>
              <li>
                The above treatment should be used in conjunction with polymeric
                sludge conditioners for better results.
              </li>
              <li>
                In this method of treatment precipitation of calcium &amp;
                magnesium, either partially or completely, are induced by
                maintaining higher pH &amp; higher alkalinity.
              </li>
              <li>
                The residual hardness, if present, should be controlled with the
                addition of polymeric sludge conditioners.
              </li>
            </ul>
          </div>
        </div> 
        <div className="card my-3">
          <span className="card-header" name="PHOSPHATE">
            <a name="PHOSPHATE"></a>
            <strong>PHOSPHATE TREATMENT PROGRAM </strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>
          <div className="card-body ms-3">
            <ul>
              <li>
                This method of treatment is implemented in boilers where DM or
                RO water is used as boiler feed water make up. Also in boilers
                having operating pressure above 20Kgs/cm2 which should be having
                DM or RO water make up.
              </li>
              <li>
                In this method calcium is precipitated as calcium ortho
                phosphate sludge with the addition of blended phosphates.
              </li>
              <li>
                This treatment is recommended in boilers where the feed water
                contains low sodium, low silica &amp; have more than 90%
                condensate return.
              </li>
            </ul>
          </div>
        </div>
        <div className="card my-3">
          <span className="card-header">
            <strong>
              <a name="MAIN2" id="MAIN2"></a>OTHER ASPECTS OF BOILER WATER
              TREATMENT
            </strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>

          <div className="card-body">
            <div className="container-row">
              <u>
                <i className="site-text-primary me-2 m-t5" />
                <a href="#OXYGEN">OXYGEN SCAVENGING</a>
              </u>
              <br />
              <u>
                <i className="site-text-primary me-2 m-t5" />
                <a href="#SLUDGE">SLUDGE CONDITIONING</a>
              </u>
              <br />
              <u>
                <i className="site-text-primary me-2 m-t5" />
                <a href="#ALKALINITY">ALKALINITY BUILDER</a>
              </u>
              <br />
              <u>
                <i className="site-text-primary me-2 m-t5" />
                <a href="#CARRY">PREVENTION OF CARRY OVER</a>
              </u>
              <br />
            </div>
          </div>
        </div>
        <div className="card my-3">
          <span className="card-header">
            <strong>
              <u>OXYGEN SCAVENGING</u>
            </strong>
            <a name="OXYGEN"></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN2">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>
          <div className="card-body ms-3">
            <ul>
              <li>
                Boiler feed water contains dissolved gasses viz., Oxygen, Carbon
                dioxide and hydrozen sulphide.
              </li>
              <li>
                These gasses are removed through degasser. Oxygen in particular
                is removed through de-aerator.
              </li>
              <li>The presence of Oxygen causes pitting corrosion.</li>
              <li>The presence of CO2 causes grooving corrosion.</li>
              <li>
                The presence of CO2 generally occur in post boiler section where
                it is neutralized with the help of amines.
              </li>
              <li>
                Oxygen is removed with the addition of Hydrazine or Sulphites.
              </li>
              <li>
                Sulphites are used in boilers having operating pressure upto
                600psi. Above 600psi sulphites will decompose and loose their
                properties.
              </li>
              <li>
                Above 600psi boilers hydrazine is used for removing oxygen.
              </li>
              <li>
                Apart from removing oxygen, hydrazine forms a magnatite film
                over the metal surfaces in boiler system.
              </li>
              <li>Also it contributes to the increase of pH.</li>
              <li>
                Demerits of hydrazine are: 1. It is carcinogenic. 2. It reacts
                very slow.
              </li>
              <li>
                To enhance the reaction rate it is supplemented with a
                catalyst.&nbsp;
              </li>
            </ul>
          </div>
        </div>
        <div className="card my-3">
          <span className="card-header">
            <strong>SLUDGE CONDITIONING</strong>
            <a name="SLUDGE"></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN2">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>
          <div className="card-body ms-3">
            <ul>
              <li>
                Boiler water contains suspended particles like precipitation of
                calcium, magnesium, silica, etc. These salts flock together and
                settle down at the bottom of boiler drum, low velocity areas,
                may adhere to boiler tubes to form a scale.
              </li>
              <li>
                To prevent the above problem, a medium molecular weight
                polymeric dispersant is added. This dispersant gives same ionic
                charges to the precipitated salts, due to which repulsion occurs
                among the particles. Hence all these suspended matter is kept in
                kinetic motion and can be removed freely through blow down.
              </li>
            </ul>
          </div>
        </div>
        <div className="card my-3">
          <span className="card-header">
            <strong>ALKALINITY BUILDER</strong>
            <a name="ALKALINITY"></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN2">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>
          <div className="card-body ms-3">
            <ul>
              <li>
                Total alkalinity should be maintained at 15 to 20% of TDS in the
                boiler water.
              </li>
              <li>
                Certain amount of caustic alkalinity is required to be
                maintained for following reasons:
              </li>
              <li>To keep silica in soluble state.</li>
              <li>To keep iron in soluble state.</li>
              <li>
                In Phosphate program, in presence of caustic alkalinity
                magnesium preferentially reacts with hydroxyl ions and
                precipitate as mg(OH)2 which is a loose sludge and can be
                removed through blow down freely.
              </li>
              <li>
                Otherwise magnesium reacts with phosphate and form magnesium
                phosphate which is a thick slimy sludge and can not be removed
                through blow down.
              </li>
            </ul>
          </div>
        </div>
        <div className="card my-3">
          <span className="card-header">
            <strong>CARRY OVER</strong>
            <a name="CARRY"></a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
            <a href="#MAIN2">
              {" "}
              <u>GOTO TOP</u>
            </a>{" "}
            ]
          </span>
          <div className="card-body ms-3">
            <ul>
              <li>
                Some times suspended particles go along with steam. This is
                called as carry over. This occurs due to the following reasons:
              </li>
              <li>
                Excess alkalinity causes foaming. When steam separates from
                water surface, the bubbles at the interface breaks and the
                suspended particles enveloped in the bubbles gets carried along
                with steam.
                <br />
                Operating the boiler more than the designed capacity also causes
                carry over.
              </li>
              <li>
                Presence of oil, organic matter &amp; suspended matter in excess
                causes carry over.&nbsp;&nbsp; The carry over causes scaling in
                the post boiler sections.
              </li>
            </ul>
          </div>
        </div> */
}
