import React, { useState } from "react";
import { publicUrlFor } from "../../../../globals/constants";

function KnowledgePage() {
  const [isHovered, setIsHovered] = useState(false);
  const [id, setId] = useState("0");

  const handleMouseEnter = (id) => {
    setId(id);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setId("0");
    setIsHovered(false);
  };

  const textStyle = {
    display: "inline-block",
    transition: "transform 0.3s ease-in-out",
    transform: isHovered ? "scale(1.1)" : "scale(1)",
  };
  const textStyle2 = {};

  return (
    // <div className="section-full  p-t30 p-b50 bg-gray">
    //   <div className="container">
    //     <div
    //       className="my-3"
    //       id="1"
    //       style={id == "1" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("1")}
    //       onMouseLeave={handleMouseLeave}
    //     >
    //       <span className="">

    //
    //         WATER
    //       </span>
    //       <div className="">
    //         {/* <span className="card-title">Special title treatment</h5> */}
    //         <p className="card-text fs-6">
    //           Water is
    //           tasteless, odorless, colorless, and liquid in its pure form. It is
    //           the only inorganic material that occurs in three forms (Ice,
    //           Water, Vapors) within the earth’s natural temperature range.
    //           Because water is converted to steam at convenient temperature, it
    //           is ideal of generating power or steam, or conducting heat.
    //         </p>
    //       </div>
    //     </div>

    //     <div
    //       className="my-3"
    //       id="2"
    //       style={id == "2" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("2")}
    //       onMouseLeave={handleMouseLeave}
    //     >
    //       <span className="">
    //
    //         CHEMICAL COMPOSITION OF WATER
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The common
    //           formula is H2O however there are several hybrid forms of water in
    //           all supplies. Water contains about 300 PPM of deuterium oxide D2O
    //           or heavy water. This does not quenched thirst or makes plant grow.
    //           But in pure form it has found use in moderating nuclear reactor.
    //           Another form of water is tritium oxide T2O is made radioactive by
    //           cosmic rays. For all practical purpose though only ordinary water
    //           H2O is considered for use in Boilers, Cooling Tower, Chilling
    //           Plants, Heat Exchangers etc.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="3"
    //       style={id == "3" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("3")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         TYPETYPES OF IMPURITIES IN WATER (Impurities in natural waters may
    //         classified as)
    //       </h5>
    //       <div className="">
    //         <ol className="list-group list-group-numbered">
    //           <li className="list-group-item">Dissolved solids</li>
    //           <li className="list-group-item">Dissolved gases.</li>
    //           <li className="list-group-item">Suspended matter.</li>
    //           <li className="list-group-item">Microbiological contamination.</li>
    //         </ol>
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Water is a
    //           universal solvent, it dissolves the rocks and soil it contacts. It
    //           dissolves gases from the air and gases given of from organic in
    //           the soil. It picks up suspended matter from the earth. It is also
    //           subject to contamination with industrial waste, oils, and process
    //           material in general the type of impurities water contains depend
    //           on what it contacts, the amount of impurities depends of the
    //           contact time.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="4"
    //       style={id == "4" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("4")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         KINDS OF DISSOLVED SOLIDS IN WATER{" "}
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The minerals that
    //           water picks up from rock consist chiefly of calcium carbonate
    //           (limestone), magnesium carbonate (dolomite), calcium sulfate
    //           (gypsum), silica (sand), magnesium sulfate (Epsom salt), sodium
    //           chloride (salt), Hydrate sodium sulfate and small quantities of
    //           iron, manganese, fluorides, aluminum, and other substances. Waste
    //           from mines and certain industrial processes make some surface
    //           waters very acid, while minerals in the earth make some ground
    //           waters very alkaline, sometimes nitrates and phosphate found in
    //           water and in many cases can be associated with sewage
    //           contamination.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="5"
    //       style={id == "5" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("5")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         HARDNESS
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Water contains
    //           large amount of calcium and magnesium minerals are hard to wash
    //           with. The calcium and magnesium compounds react with soap to form
    //           a curd in the water. The compounds are referred to as water
    //           hardness. These amounts of hardness in natural water may vary from
    //           several 00 PPM to 500 PPM. Because calcium and magnesium compound
    //           are relatively insoluble in water they tend to precipitate out
    //           causing scale and deposit problems. The hardness of the water
    //           source is an important consideration in determination suitability
    //           of the water for steam generation & Cooling purpose. Hardness is
    //           perhaps the most troublesome of all water impurities. It may cause
    //           scale formation in your system. Hardness is due to soluble
    //           compounds of calcium and magnesium in the form of calcium
    //           carbonate and magnesium carbonate.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="6"
    //       style={id == "6" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("6")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         pH
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Control of pH is
    //           critical for the majority of cooling water treatment programs. In
    //           general, when pH is below recommended ranges, the chance of
    //           corrosion increases and when pH is above recommended ranges the
    //           chance of scale formation increases. The effectiveness of many
    //           biocides also depends on pH therefore high or low pH may allow
    //           growth and development of microbiological problems. A good control
    //           point is 8.2 to 8.5.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="7"
    //       style={id == "7" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("7")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         T.D.S
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When raw water
    //           from its source is high in its TDS contents, it is desirable to
    //           remove it in pretreatment preferably since cooling water
    //           concentrate by 3-5 times even normal blow-down are maintained.
    //           When TDS crosses the prescribed limit for a cooling tower, it
    //           should be controlled by blow down if necessary more than a shift
    //           as cooling water blows down is the only direct method of lowering
    //           concentration of solids.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="8"
    //       style={id == "8" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("8")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         ALKALINITY
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Alkalinity and pH
    //           are related because increase in pH indicates in alkalinity and
    //           vice versa. As with pH, alkalinity below-recommended ranges
    //           increases the chances for corrosion, those above-recommended
    //           ranges increase the chances for scale formation. When corrosion
    //           and scale problems exist, fouling will also be a problem.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="9"
    //       style={id == "9" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("9")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         DISSOLVED GASES
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Water dissolved
    //           varying amount of air, which is composed of 21% oxygen, 78%
    //           nitrogen 1% other (including 0.03%-0.06% carbon dioxide) Oxygen is
    //           soluble up to 9 PPM in water at room temperature and at
    //           atmospheric pressure. The solubility of oxygen decreases as the
    //           temperature of the water increases. But water under pressure can
    //           hold larger quantities of dissolved oxygen. Although nitrogen is
    //           dissolved in natural water, it is an inert gas and has little
    //           effect on the water used in boilers & cooling system.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="10"
    //       style={id == "10" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("10")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         SCALE DEPOSITS
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Scale can be
    //           defined as the deposit of sparingly soluble inorganic minerals on
    //           the metal surface. These deposits or scales are typically dense,
    //           non-conducting, hard, tightly bound particles that tenaciously
    //           adhere to the metallic surface. Scales such as calcium carbonate
    //           and calcium sulphates are formed on the heat transfer surface
    //           since these substances possess inverse solubility that is these
    //           substances are more soluble in cold water than in hot.
    //         </p>
    //         <p>
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The factors that
    //           determine the scaling tendencies of water are temperature,
    //           alkalinity, pH, hardness and dissolved solids. If any of these
    //           changes, scaling tendencies also will change. For example, and
    //           increase in pH or alkalinity can increase calcium carbonate scale
    //           formation, while silica scales decrease under these conditions.
    //           Scale results when the amount of scale forming materials dissolved
    //           in the water exceeds its saturation level, other dissolved solids
    //           in water also influence the scale forming tendencies. In general,
    //           the higher the level of scale forming dissolved solids, the
    //           greater the chances of scale formation.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3 bdr-1 bdr-gray hover-shadow"
    //       id="11"
    //       style={id == "11" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("11")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         SCALE DEPOSIT CONTROL
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Scale can be
    //           effectively controlled in a re-circulation cooling system by one
    //           or more of the following methods.
    //         </p>
    //         <ol className="list-group list-group-numbered">
    //           <li className="list-group-item">
    //             Limiting the cycles of concentration.
    //           </li>
    //           <li className="list-group-item">
    //             Physical removal of scaling impurities (external treatment).
    //           </li>
    //           <li className="list-group-item">Chemical treatment.</li>
    //         </ol>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="12"
    //       style={id == "12" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("12")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         ALGAE
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Primitive plants,
    //           manufacture their own food by photosynthesis from water
    //           carbon-di-oxide, using chlorophyll as a catalyst. Algae growth
    //           usually occurs in sections of the cooling tower to the sun.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="13"
    //       style={id == "13" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("13")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         FUNGI
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fungi include all
    //           simple plants not containing chlorophyll in industrial cooling
    //           system. Fungi growth is generally encountered above the water
    //           level.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="14"
    //       style={id == "14" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("14")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         BACTERIA
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bacteria are the
    //           smallest, yet the most prolific and trouble some forms of life in
    //           a cooling water system. Bacteria multiply at an awesome rate by
    //           cell division.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="15"
    //       style={id == "15" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("15")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         BIO-FOULING CONTROL
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Microorganisms
    //           present in the cooling system are controlled by the periodic
    //           application of micro-biocides. Factor which influence the
    //           effectiveness of the biocides are (a) choice of biocide (b)
    //           concentration of the biocide per application, (c) contact time,
    //           (d) application point and (e) frequency of addition.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="16"
    //       style={id == "16" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("16")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         CURRENT METHOD OF TREATMENT AND CONTROL
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; A cooling water
    //           treatment program for all system can never be standardized.
    //           Cooling systems differ widely from a simple central
    //           air-conditioning or diesel engine cooling system to complex
    //           fertilizer plants. The quality varies depending on its cooling
    //           water supply from lake, river, bore-well or sea. The heat load,
    //           cooling range, material of construction also differ widely. System
    //           design, plant layout, metallurgy of the equipment contaminants
    //           from the process and environment, make up and re-circulating water
    //           chemistry and microbiology are the important factors influencing
    //           the design of a suitable water treatment program.
    //         </p>
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Although the
    //           problem of corrosion, scale, general fouling and bio fouling have
    //           been discussed separately, in practice all four problems occur
    //           together, but in different degrees. Therefore, a versatile and
    //           complete treatment program design is very essential to achieve
    //           satisfactory results. For this reason, Comet Water Treatment
    //           program are designed to include:
    //         </p>
    //         <ol className="list-group list-group-numbered">
    //           <li className="list-group-item">
    //             Mixed corrosion inhibitors that is effective over multi-metal
    //             systems.
    //           </li>
    //           <li className="list-group-item">
    //             Scale control additives to prevent calcium carbonate and other
    //             deposits.
    //           </li>
    //           <li className="list-group-item">
    //             Anti-foulants to prevent sedimentation fouling.
    //           </li>
    //           <li className="list-group-item">
    //             Low toxicity additives for easy handling and minimal disposal
    //             problems.
    //           </li>
    //           <li className="list-group-item">
    //             Compatibility with biocides applied.
    //           </li>
    //         </ol>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="17"
    //       style={id == "17" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("17")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         OPERATING MECHANISM OF THE TREATMENT PROGRAM
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Comet Cooling
    //           water treatment programs are non-toxic and are based on polymer
    //           inhibitors. They are effective in treating a wide range of
    //           severely corrosive to severely scaling water (re-circulating water
    //           LSI from-1.5 to +1.5)
    //         </p>
    //         <p>
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Polymer based
    //           inhibitor treatment inhibits corrosion through combined action of
    //           cathodic and anodic inhibitors formulated in a synergistic
    //           mixture. In an operating cooling system, the inhibitors are
    //           removed from the solution by the metal surfaces. The anodic
    //           inhibitor combines with the oxidized film to form a protective
    //           barrier while the cathodic inhibitor as absorbed directly into the
    //           metal. The net effect is the retardation of the corrosion process.
    //         </p>
    //         <p>
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In addition to
    //           inhibiting corrosion, poly functional inhibitor treatments prevent
    //           mineral deposition and fouling. This protection is achieved by
    //           combining the inhibitor with an organic sequestering agent and
    //           polymeric anti fouling such as homo polymer Comet. Where
    //           water quality dictates, pH control can be used without adversely
    //           affecting inhibitor performance.
    //         </p>
    //       </div>
    //     </div>

    //     <div className="my-3"
    //       id="18"
    //       style={id == "18" ? textStyle : textStyle2}
    //       onMouseEnter={() => handleMouseEnter("18")}
    //       onMouseLeave={handleMouseLeave}>
    //       <span className="">
    //
    //         CONCLUSION
    //       </h5>
    //       <div className="">
    //         <p className="card-text">
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Effective
    //           scale/corrosion/fouling prevention will only be achieved if the
    //           correct treatment is prescribed and applied. Therefore, for any
    //           cooling system and approval of the plant, water quality and
    //           problems (degree to which each problem prevails) is essential.
    //           This is best done by a careful plant survey prior to introduction
    //           of any treatment program. The program designed should be balanced
    //           blend of correct operation practices augmented by the use of
    //           chemical inhibitors combined with a simple but effective schedule
    //           of monitoring and control. The proper selection and application of
    //           micro-biocides are essential if the integrity and efficiency of
    //           the cooling system is to be maintained.
    //         </p>
    //         <p>
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The effectiveness
    //           of the microbiological control program should be monitored by
    //           regular visual inspection of exposed cooling tower surfaces and by
    //           periodic microbiological analysis of the re-circulating water.
    //         </p>
    //         <p>
    //           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cooling water
    //           management in essence is specialized job and can be done
    //           effectively only by companies with expertise in water treatment
    //           who can supply not only plant and chemicals but also service by
    //           regular visits to monitor and control the treatment program
    //           implemented and train the staff in correct plant operation and
    //           control.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      className="section-full p-t30 p-b50"
      // style={{
      //   backgroundImage: `url(${publicUrlFor(
      //     "images/background/bg15.jpg"
      //   )})`,
      // }}
    >
      <div className="container justify-contect-center">
        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            WATER
          </span>
          <div className=" m-t30">
            {/* <span className="card-title">Special title treatment</span> */}
            <p
              className="fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              Water is tasteless, odorless, colorless, and liquid in its pure
              form. It is the only inorganic material that occurs in three forms
              (Ice, Water, Vapors) within the earth’s natural temperature range.
              Because water is converted to steam at convenient temperature, it
              is ideal of generating power or steam, or conducting heat.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            CHEMICAL COMPOSITION OF WATER
          </span>
          <div className="m-t30">
            <p
              className="fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              The common formula is H2O however there are several hybrid forms
              of water in all supplies. Water contains about 300 PPM of
              deuterium oxide D2O or heavy water. This does not quenched thirst
              or makes plant grow. But in pure form it has found use in
              moderating nuclear reactor. Another form of water is tritium oxide
              T2O is made radioactive by cosmic rays. For all practical purpose
              though only ordinary water H2O is considered for use in Boilers,
              Cooling Tower, Chilling Plants, Heat Exchangers etc.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            TYPES OF IMPURITIES IN WATER (Impurities in natural waters may
            classified as)
          </span>
          <div className=" m-t30">
            <p
              className="fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              1. &nbsp; Dissolved solids
              <br /> 2. &nbsp;Dissolved gases. deposits.
              <br /> 3. &nbsp; Suspended matter.
              <br /> 4. &nbsp; Microbiological contamination.
            </p>
            {/* <ol className="list-group list-group-numbered">
              <li className="list-group-item">Dissolved solids</li>
              <li className="list-group-item">Dissolved gases.</li>
              <li className="list-group-item">Suspended matter.</li>
              <li className="list-group-item">
                Microbiological contamination.
              </li>
            </ol> */}
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Water is a universal solvent, it dissolves the rocks and soil it
              contacts. It dissolves gases from the air and gases given of from
              organic in the soil. It picks up suspended matter from the earth.
              It is also subject to contamination with industrial waste, oils,
              and process material in general the type of impurities water
              contains depend on what it contacts, the amount of impurities
              depends of the contact time.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            KINDS OF DISSOLVED SOLIDS IN WATER{" "}
          </span>
          <div className="">
            <p
              className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              The minerals that water picks up from rock consist chiefly of
              calcium carbonate (limestone), magnesium carbonate (dolomite),
              calcium sulfate (gypsum), silica (sand), magnesium sulfate (Epsom
              salt), sodium chloride (salt), Hydrate sodium sulfate and small
              quantities of iron, manganese, fluorides, aluminum, and other
              substances. Waste from mines and certain industrial processes make
              some surface waters very acid, while minerals in the earth make
              some ground waters very alkaline, sometimes nitrates and phosphate
              found in water and in many cases can be associated with sewage
              contamination.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            HARDNESS
          </span>
          <div className="">
            <p
              className="fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Water contains large amount of calcium and magnesium minerals are
              hard to wash with. The calcium and magnesium compounds react with
              soap to form a curd in the water. The compounds are referred to as
              water hardness. These amounts of hardness in natural water may
              vary from several 00 PPM to 500 PPM. Because calcium and magnesium
              compound are relatively insoluble in water they tend to
              precipitate out causing scale and deposit problems. The hardness
              of the water source is an important consideration in determination
              suitability of the water for steam generation & Cooling purpose.
              Hardness is perhaps the most troublesome of all water impurities.
              It may cause scale formation in your system. Hardness is due to
              soluble compounds of calcium and magnesium in the form of calcium
              carbonate and magnesium carbonate.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            pH
          </span>
          <div className=" m-t50">
            <p
              className="fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              Control of pH is critical for the majority of cooling water
              treatment programs. In general, when pH is below recommended
              ranges, the chance of corrosion increases and when pH is above
              recommended ranges the chance of scale formation increases. The
              effectiveness of many biocides also depends on pH therefore high
              or low pH may allow growth and development of microbiological
              problems. A good control point is 8.2 to 8.5.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            T.D.S
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              When raw water from its source is high in its TDS contents, it is
              desirable to remove it in pretreatment preferably since cooling
              water concentrate by 3-5 times even normal blow-down are
              maintained. When TDS crosses the prescribed limit for a cooling
              tower, it should be controlled by blow down if necessary more than
              a shift as cooling water blows down is the only direct method of
              lowering concentration of solids.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            ALKALINITY
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Alkalinity and pH are related because increase in pH indicates in
              alkalinity and vice versa. As with pH, alkalinity
              below-recommended ranges increases the chances for corrosion,
              those above-recommended ranges increase the chances for scale
              formation. When corrosion and scale problems exist, fouling will
              also be a problem.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            DISSOLVED GASES
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Water dissolved varying amount of air, which is composed of 21%
              oxygen, 78% nitrogen 1% other (including 0.03%-0.06% carbon
              dioxide) Oxygen is soluble up to 9 PPM in water at room
              temperature and at atmospheric pressure. The solubility of oxygen
              decreases as the temperature of the water increases. But water
              under pressure can hold larger quantities of dissolved oxygen.
              Although nitrogen is dissolved in natural water, it is an inert
              gas and has little effect on the water used in boilers & cooling
              system.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            SCALE DEPOSITS
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Scale can be defined as the deposit of sparingly soluble inorganic
              minerals on the metal surface. These deposits or scales are
              typically dense, non-conducting, hard, tightly bound particles
              that tenaciously adhere to the metallic surface. Scales such as
              calcium carbonate and calcium sulphates are formed on the heat
              transfer surface since these substances possess inverse solubility
              that is these substances are more soluble in cold water than in
              hot.
            </p>
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              The factors that determine the scaling tendencies of water are
              temperature, alkalinity, pH, hardness and dissolved solids. If any
              of these changes, scaling tendencies also will change. For
              example, and increase in pH or alkalinity can increase calcium
              carbonate scale formation, while silica scales decrease under
              these conditions. Scale results when the amount of scale forming
              materials dissolved in the water exceeds its saturation level,
              other dissolved solids in water also influence the scale forming
              tendencies. In general, the higher the level of scale forming
              dissolved solids, the greater the chances of scale formation.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            SCALE DEPOSIT CONTROL
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Scale can be effectively controlled in a re-circulation cooling
              system by one or more of the following methods.
            </p>
            <p
              className="fs-6"
              style={{
                fontWeight: "400",
              }}
            >
              1. &nbsp; Limiting the cycles of concentration.
              <br /> 2. &nbsp; Physical removal of scaling impurities (external
              treatment). deposits.
              <br /> 3. &nbsp; Chemical treatment.
            </p>
            {/* <ol className="list-group list-group-numbered">
              <li className="list-group-item">
                Limiting the cycles of concentration.
              </li>
              <li className="list-group-item">
                Physical removal of scaling impurities (external treatment).
              </li>
              <li className="list-group-item">Chemical treatment.</li>
            </ol> */}
          </div>
        </div>

        <div className="my-3">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            ALGAE
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Primitive plants, manufacture their own food by photosynthesis
              from water carbon-di-oxide, using chlorophyll as a catalyst. Algae
              growth usually occurs in sections of the cooling tower to the sun.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            FUNGI
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Fungi include all simple plants not containing chlorophyll in
              industrial cooling system. Fungi growth is generally encountered
              above the water level.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            BACTERIA
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Bacteria are the smallest, yet the most prolific and trouble some
              forms of life in a cooling water system. Bacteria multiply at an
              awesome rate by cell division.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            BIO-FOULING CONTROL
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Microorganisms present in the cooling system are controlled by the
              periodic application of micro-biocides. Factor which influence the
              effectiveness of the biocides are (a) choice of biocide (b)
              concentration of the biocide per application, (c) contact time,
              (d) application point and (e) frequency of addition.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            CURRENT METHOD OF TREATMENT AND CONTROL
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              A cooling water treatment program for all system can never be
              standardized. Cooling systems differ widely from a simple central
              air-conditioning or diesel engine cooling system to complex
              fertilizer plants. The quality varies depending on its cooling
              water supply from lake, river, bore-well or sea. The heat load,
              cooling range, material of construction also differ widely. System
              design, plant layout, metallurgy of the equipment contaminants
              from the process and environment, make up and re-circulating water
              chemistry and microbiology are the important factors influencing
              the design of a suitable water treatment program.
            </p>
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Although the problem of corrosion, scale, general fouling and bio
              fouling have been discussed separately, in practice all four
              problems occur together, but in different degrees. Therefore, a
              versatile and complete treatment program design is very essential
              to achieve satisfactory results. For this reason, Comet Water
              Treatment program are designed to include:
            </p>
            <p
              className="fs-6 m-b20"
              style={{
                fontWeight: "400",
              }}
            >
              1. &nbsp; Mixed corrosion inhibitors that is effective over
              multi-metal,
              <br /> 2. &nbsp;Scale control additives to prevent calcium
              carbonate and other deposits.,
              <br /> 3. &nbsp; Anti-foulants to prevent sedimentation fouling.
              <br /> 4. &nbsp; Low toxicity additives for easy handling and
              minimal disposal problems.
              <br /> 5. &nbsp; Compatibility with biocides applied.
            </p>
            {/* <ol className="list-group list-group-numbered m-t50">
              <li className="list-group-item">
                Mixed corrosion inhibitors that is effective over multi-metal
                systems.
              </li>
              <li className="list-group-item">
                Scale control additives to prevent calcium carbonate and other
                deposits.
              </li>
              <li className="list-group-item">
                Anti-foulants to prevent sedimentation fouling.
              </li>
              <li className="list-group-item">
                Low toxicity additives for easy handling and minimal disposal
                problems.
              </li>
              <li className="list-group-item">
                Compatibility with biocides applied.
              </li>
            </ol> */}
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            OPERATING MECHANISM OF THE TREATMENT PROGRAM
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Comet Cooling water treatment programs are non-toxic and are based
              on polymer inhibitors. They are effective in treating a wide range
              of severely corrosive to severely scaling water (re-circulating
              water LSI from-1.5 to +1.5)
            </p>
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Polymer based inhibitor treatment inhibits corrosion through
              combined action of cathodic and anodic inhibitors formulated in a
              synergistic mixture. In an operating cooling system, the
              inhibitors are removed from the solution by the metal surfaces.
              The anodic inhibitor combines with the oxidized film to form a
              protective barrier while the cathodic inhibitor as absorbed
              directly into the metal. The net effect is the retardation of the
              corrosion process.
            </p>
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              In addition to inhibiting corrosion, poly functional inhibitor
              treatments prevent mineral deposition and fouling. This protection
              is achieved by combining the inhibitor with an organic
              sequestering agent and polymeric anti fouling such as homo
              polymer. Where water quality dictates, pH control can be used
              without adversely affecting inhibitor performance.
            </p>
          </div>
        </div>

        <div className="my-5">
          <span
            className=""
            style={{
              fontWeight: "460",
              fontSize: 20,
            }}
          >
            CONCLUSION
          </span>
          <div className="">
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Effective scale/corrosion/fouling prevention will only be achieved
              if the correct treatment is prescribed and applied. Therefore, for
              any cooling system and approval of the plant, water quality and
              problems (degree to which each problem prevails) is essential.
              This is best done by a careful plant survey prior to introduction
              of any treatment program. The program designed should be balanced
              blend of correct operation practices augmented by the use of
              chemical inhibitors combined with a simple but effective schedule
              of monitoring and control. The proper selection and application of
              micro-biocides are essential if the integrity and efficiency of
              the cooling system is to be maintained.
            </p>
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              The effectiveness of the microbiological control program should be
              monitored by regular visual inspection of exposed cooling tower
              surfaces and by periodic microbiological analysis of the
              re-circulating water.
            </p>
            <p
              className="card-text fs-6 m-t50"
              style={{
                fontWeight: "400",
              }}
            >
              Cooling water management in essence is specialized job and can be
              done effectively only by companies with expertise in water
              treatment who can supply not only plant and chemicals but also
              service by regular visits to monitor and control the treatment
              program implemented and train the staff in correct plant operation
              and control.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KnowledgePage;
