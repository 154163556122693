import FastImage from "../../../../globals/elements/fastimg";
import { route } from "../../../../globals/constants";
import { publicUrlFor } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

function ContactUsPage() {
  const [formData, setFormData] = useState({
    companyName: "",
    dealingWith: "",
    email: "",
    contactNo: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://cometapi.agmetech.com/api/mail/contact-us",
        {
          CompanyName: formData.companyName,
          DealingWith: formData.dealingWith,
          email: formData.email,
          contactNo: formData.contactNo,
          Message: formData.message,
        }
      );
      alert("Contact information submitted successfully!");
      console.log(response.data);
      // Reset form fields
      setFormData({
        companyName: "",
        dealingWith: "",
        email: "",
        contactNo: "",
        message: "",
      });
    } catch (error) {
      alert("There was an error submitting the form.");
      console.error(error);
    }
  };
  return (
    <>
      <div
        className="section-full p-t60"
        //   style={{
        //   backgroundImage: `url(${publicUrlFor(
        //     "images/background/bg15.jpg"
        //   )})`,
        // }}
      >
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-6 col-md-6">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/bgimg/banner_contactus.jpg"
                width={500}
                height={15}
                alt="image"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">
                      Our goal is to deliver efficient and high-quality water
                      treatment solutions to all type of industry.
                    </h2>
                    <div className="mt-separator-outer m-b10">
                      <div className="mt-separator bg-warning  m-t15 m-b30" />
                    </div>
                  </div>
                  <p
                    className="m-b15"
                    style={{
                      fontWeight: "400",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    We believe in tailoring our solutions to meet the unique
                    needs of each customer. Our focus is on delivering
                    innovative and customized water treatment solutions.
                  </p>
                  <ul
                    className="list-check-circle warning m-t15"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>Cutting-edge chemical technology</li>
                    <li>Thorough equipment functionality assessments</li>
                    <li>Pioneering manufacturing approaches</li>
                    <li>
                      Extensive experience in water treatment applications
                    </li>
                  </ul>

                  {/* <div className="text-center p-tb20">
                    <NavLink
                      className="mfp-link"
                      to={route.services.SERVICES}
                      // title="wish list"
                    >
                      <button
                        className="site-button icon-arrow bg-warning"
                        type="button"
                      >
                        Our Services&nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </button>
                    </NavLink>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-6">
            </div> */}
              {/* </div>{" "} */}
              {/* </div> */}
            </div>{" "}
            {/* <div className="row py-15">
              <h4 className="site-text-primary">Phone number</h4>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 15,
                }}
              >
                +91-98240 40260
              </p>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 15,
                }}
              >
                +91-98244 97153
              </p>
              <h4 className="site-text-primary">Email address</h4>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 16,
                }}
              >
                info@cometgroup.co.in
              </p>

              <h4 className="site-text-primary">Address info</h4>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 15,
                }}
              >
                FF-01 Block C-2, Nirman Complex, R C Technical Road, Ghatlodia,
                Ahmedabad{" "}
              </p>
            </div> */}
            </div>
          </div>
      </div>
    </>
  );
}
export default ContactUsPage;
