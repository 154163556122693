import { useEffect, useLayoutEffect, useState } from "react";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { Link, NavLink, useLocation } from "react-router-dom";

function NewSectionHeader() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation(); // Get current location

  useLayoutEffect(() => {
    loadScript("js/sticky-header.js");
    console.log(location.pathname);
  });

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  // Function to check if the current path is active
  const isPathActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <header
        className={
          "site-header header-style-1 mobile-sider-drawer-menu " +
          (isActive ? "active" : "")
        }
        style={{ backgroundColor: "white" }}
      >
        <div className="top-bar top-bar-liner bg-secondry">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-6">
                <ul className="list-inline tb-social-liner">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="fa fa-facebook"
                    />
                  </li>
                  <li>
                    <a href="https://twitter.com/" className="fa fa-twitter" />
                  </li>
                  <li>
                    <a
                      href="https://in.linkedin.com/"
                      className="fa fa-linkedin"
                    />
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/"
                      className="fa fa-instagram"
                    />
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-md-end text-center">
                <ul className="list-unstyled  tb-info-liner">
                  <li>
                    <i className="fa fa-phone site-text-primary" />
                    +91-98240 40260
                  </li>
                  <li>
                    <i className="fa fa-envelope site-text-primary" />
                    info@cometgroup.co.in
                  </li>
                  <li>
                    <i className="fa fa-location-arrow site-text-primary" />
                    Ahmedabad
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="header-middle py-0">
          <div className="container">
            <div className="logo-header ms-0">
              <NavLink to={route.home.HOME1}>
                <FastImage
                  src="media/logos/Comet-logo.png"
                  width={216}
                  height={37}
                  alt="Comet Logo"
                />
              </NavLink>
            </div>
            <div className="header-info">
              <ul className="list-inline mb-0">
                <li>
                  <a href={route.pages.CONTACTUS}>
                    <button className="site-button bdr-primary">
                      Contact Us
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="container full-w-screen-2xl container mx-auto px-4">
          <div className="row justify-content-between align-items-center">
            <div className="col-12"> */}
        <div className="sticky-header main-bar-wraper">
          <div
            className="main-bar header-botton"
            style={{ backgroundColor: "white" }}
          >
            <div className="container">
              {/* Mobile Menu Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler collapsed"
                onClick={handleToggleMenu}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              <div className="logo-show-in-mobile">
                <NavLink to={route.home.HOME1}>
                  <FastImage
                    src="media/logos/Comet-logo.png"
                    width={220}
                    height={40}
                    alt="Comet Logo"
                  />
                </NavLink>
              </div>
              {/* <div className="header-info"> */}
              <div className="header-nav wadge navbar-collapse collapse lg:flex justify-content-center text-center">
                <ul
                  className="nav navbar-nav nav-pad py-1 text-start active"
                  style={{ backgroundColor: "#D3E3F3" }}
                >
                  <li
                    className={
                      isPathActive(route.home.HOME1) || isPathActive("/")
                        ? "active"
                        : ""
                    }
                  >
                    <NavLink
                      to={route.home.HOME1}
                      onClick={handleToggleMenu}
                      style={{ fontWeight: "normal" }} // Ensure font-weight is normal
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      to={route.pages.product.PRODUCTS}
                      onClick={handleToggleMenu}
                      style={{ fontWeight: "normal" }} // Ensure font-weight is normal
                    >
                      Our Products
                      <i className="fa fa-chevron-down" />
                    </NavLink>
                    <ul className="sub-menu">
                      <li onClick={handleToggleMenu}>
                        <NavLink
                          to={route.pages.product.BoilerWaterChemicals}
                          style={{ fontWeight: "normal" }} // Ensure font-weight is normal
                        >
                          BOILER WATER CHEMICALS
                        </NavLink>
                      </li>
                      <li
                        onClick={handleToggleMenu}
                        // className={
                        //   isPathActive(route.pages.product.CoolingWaterChemicals) ? "active" : ""
                        // }
                      >
                        <NavLink
                          to={route.pages.product.CoolingWaterChemicals}
                          style={{ fontWeight: "normal" }}
                        >
                          COOLING WATER CHEMICALS
                        </NavLink>
                      </li>
                      <li onClick={handleToggleMenu} className="text-uppercase">
                        <NavLink
                          to={route.pages.product.EffluentTreatmentChemicals}
                          style={{ fontWeight: "normal" }}
                        >
                          Effluent Treatment Chemicals
                        </NavLink>
                      </li>
                      <li onClick={handleToggleMenu}>
                        <NavLink
                          to={route.pages.product.SideTreatments}
                          style={{ fontWeight: "normal" }}
                        >
                          FIRE SIDE TREATMENTS
                        </NavLink>
                      </li>
                      <li onClick={handleToggleMenu}>
                        <NavLink
                          to={route.pages.product.RoAntiScalantKits}
                          style={{ fontWeight: "normal" }}
                        >
                          RO ANTI-SCALANT & DE-SCALANT
                        </NavLink>
                      </li>
                      <li onClick={handleToggleMenu}>
                        <NavLink
                          to={route.pages.product.TestKits}
                          style={{ fontWeight: "normal" }}
                        >
                          TEST KITS
                        </NavLink>
                      </li>
                      <li onClick={handleToggleMenu} className="text-uppercase">
                        <NavLink
                          to={route.pages.product.DescalingChemicals}
                          style={{ fontWeight: "normal" }}
                        >
                          Descaling Chemicals
                        </NavLink>
                      </li>
                      <li onClick={handleToggleMenu}>
                        <NavLink
                          to={route.pages.product.ChillingWaterChemicals}
                          style={{ fontWeight: "normal" }}
                        >
                          CHILLING WATER CHEMICALS
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      isPathActive(route.services.SERVICES) ? "active" : ""
                    }
                  >
                    <NavLink
                      to={route.services.SERVICES}
                      onClick={handleToggleMenu}
                      style={{ fontWeight: "normal" }}
                    >
                      Services
                    </NavLink>
                  </li>
                  <li
                    className={
                      isPathActive(route.pages.knowledge.KNOWLEDGE)
                        ? "active"
                        : ""
                    }
                  >
                    <NavLink
                      to={route.pages.knowledge.KNOWLEDGE}
                      onClick={handleToggleMenu}
                      style={{ fontWeight: "normal" }}
                    >
                      Knowledge
                    </NavLink>
                  </li>
                  <li
                    className={isPathActive(route.pages.ABOUT) ? "active" : ""}
                  >
                    <NavLink
                      to={route.pages.ABOUT}
                      onClick={handleToggleMenu}
                      style={{ fontWeight: "normal" }}
                    >
                      About us
                    </NavLink>
                  </li>
                  <li
                    className={
                      isPathActive(route.pages.CONTACTUS) ? "active" : ""
                    }
                  >
                    <NavLink
                      to={route.pages.CONTACTUS}
                      onClick={handleToggleMenu}
                      style={{ fontWeight: "normal" }}
                    >
                      Contact us
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* </div>
          </div> */}
        </div>
      </header>
    </>
  );
}
export default NewSectionHeader;
