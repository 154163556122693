import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function FireSideTreatment() {
  return (
    <div className="section-full p-t30 p-b50"
    // style={{
    //   backgroundImage: `url(${publicUrlFor(
    //     "images/background/bg15.jpg"
    //   )})`,
    // }}
    >
      <div className="container">
        {" "}
        <div className="my-5">
          <div className="ms-3">
              <p
              className=""
              style={{
                fontWeight: "460",
                fontSize: 24,
              }}
            >
             Fire Side Treatments
            </p>
            <ul className="ms-3 fs-6 m-t10"
              style={{
                fontWeight: "400",
              }}>
              <li>To reduce fire side problems like clinker formation</li>
              <li>Increase in combustion efficiency</li>
              <li>Reduction in deposit formation</li>
              <li>
                Reduction in glossy scale
                <br />
              </li>
            </ul>
          </div>
        </div>{" "}
        <div className="my-5 bdr-1">
          <span className="card-header">
          <p
              className="ms-3"
              style={{
                fontWeight: "460",
                fontSize: 24,
              }}
            >Solid Fuel</p>
          </span>
          <div className="ms-3">
            <p className="fs-6 m-t10"
              style={{
                fontWeight: "400",
              }}>
              Formulated to treat solid fuel and to get maximum benefit out of
              fuel being used lead to be used along with fuel to protect clinker
              formation to main uniform heat in the furnace and to control the
              carbon-mono-oxide in the fuel gas.
            </p>
          </div>
        </div>
        <div className="my-2 bdr-1">
          <span className="card-header">
          <p
              className="ms-3"
              style={{
                fontWeight: "460",
                fontSize: 24,
              }}
            >Liquid Fuel</p>
          </span>
          <div className="ms-3 fs-6 m-t10"
              style={{
                fontWeight: "400",
              }}>
            <p>
              Formulated to disperse water in the fuel, control corrosion in the
              fuel tank, dissolve sludge in the fuel. Thus maximise fuel
              efficiency.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FireSideTreatment;
